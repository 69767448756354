import {
  faFilePdf,
  faMagnifyingGlassPlus,
  faMagnifyingGlassMinus,
  faTimes,
  faDownload,
  faFileZipper,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutDesktop from "../../utils/components/LayoutDeskTop/LayoutDesktop";
import SearchBar from "../../utils/components/SearchBar/SearchBar";
import AlphabetColor from "../../utils/components/SideBar/AlphabetColor";
import SearchView from "../../utils/components/SearchView/SearchView";
import { Spinner } from "react-bootstrap";
import { GetRequest } from "../../utils/repository/RequestMaker";
import Endpoints from "../../utils/repository/Endpoints";
import b64toBlob from "../../utils/sharedLogics/b64toBlob";
import {
  setFolderTitle,
  setSachgruppeTitle,
} from "../FileManager/fileMangerSlice";
import useBackButton from "../../utils/hooks/useBackButton";

const DesktopView = () => {
  const files = useSelector((state) => state.fileManager.files);

  const folderTitle = useSelector((state) => state.fileManager.sachgruppeTitle);
  const [selectedFile, setSelectedFile] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [rowHeight, setRowHeight] = useState(500);
  const searchFiles = useSelector((state) => state.search.searchFiles);
  const [currentSelected, setCurrentSelected] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);
  const [fileSaveName, setFileSaveName] = useState("");
  const dispatch = useDispatch();
  const [isPDF, setIsPDF] = useState(false);

  const iframeButtonClick = (button_id) => {
    let iframe = document.getElementById("pdf-js-viewer").contentWindow;
    let button = iframe.document.getElementById(button_id);
    button.click();
  };


  const [loginTime, setLoginTime] = useState(null);


  useEffect(() => {
    const handleLogout = () => {
      localStorage.clear();
      window.location.href = '/login';
    };

    const timeout = setTimeout(handleLogout, 30 * 60 * 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const loggedInTime = localStorage.getItem('loginTime');

    if (loggedInTime) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(loggedInTime, 10);

      if (elapsedTime >= 30 * 60 * 1000) {
        handleLogout();
      }
    } else {
      const currentTime = new Date().getTime();
      localStorage.setItem('loginTime', currentTime.toString());
      setLoginTime(currentTime);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  useEffect(() => {
    const container_h = document.getElementById("container").offsetHeight;
    const header_h = document.getElementById("header").offsetHeight;
    setRowHeight(container_h - header_h - 30);
  }, []);

  const onSelect = (id, fileId) => {
    if (currentSelected !== "") {
      document.getElementById(currentSelected).style.border = "none";
    }
    setCurrentSelected(id);
    document.getElementById(id).style.border = "2px solid black";

    GetRequest(Endpoints.file + fileId).then((res) => {
      console.log(res);
      //console.log("base64 starts with ", res.body.file_base64.charAt(0));
      // checks whether the selected file is PDF or not.
      // If it starts with U then it is ZIP, when it starts with J then it is PDF
      if (res.body.file_base64.charAt(0) == "U") {
        setIsPDF(false);
        var blob = b64toBlob(res.body.file_base64, "application/zip");
        setPdfFile(URL.createObjectURL(blob));
        setShowSpinner(false);
      } else {
        setIsPDF(true);
        var blob = b64toBlob(res.body.file_base64, "application/pdf");
        setPdfFile(URL.createObjectURL(blob));
        setShowSpinner(false);
      }
    });
  };

  useEffect(() => {
    const categorizedData = {};
    files.forEach((item) => {
      const sachgruppe = item.Inhaltstyp;
      if (!categorizedData[sachgruppe]) {
        categorizedData[sachgruppe] = [];
      }
      categorizedData[sachgruppe].push(item);
    });
    console.log(categorizedData);
  }, []);

  return (
    <LayoutDesktop>
      <div
        className="container"
        style={{ height: searchFiles.length === 0 && "100%" }}
        id="container"
      >
        <div className="header" id="header" style={{ marginTop: 20 }}>
          <SearchBar />
          <div className="display-4" style={{ marginTop: 10 }}>
            {searchFiles.length > 0 ? "Search Results" : folderTitle}
          </div>
        </div>
        {searchFiles.length > 0 ? (
          <SearchView />
        ) : (
          <div className="row" style={{ height: rowHeight }}>
            <div
              className="col-4"
              style={{
                height: "100%",
                overflowY: "scroll",
                overflowX: "scroll",
                paddingTop: 10,
              }}
            >
              {files.map((file, key) => {
                return (
                  <div
                    className="folder"
                    style={{
                      width: "100%",
                      backgroundColor:
                        AlphabetColor[
                          Array.from(file.Filename)[0].toLowerCase()
                        ],
                      padding: 10,
                      borderRadius: 10,
                      marginBottom: 10,
                    }}
                    onClick={() => {
                      console.log("File is ", file);
                      console.log("isPDF.toString() ", isPDF.toString());
                      setPdfFile(null);
                      setShowSpinner(true);
                      onSelect(key + file.Filename, file.id);
                      setSelectedFile(file.Filename);
                      setFileSaveName(file.Filename);
                    }}
                    id={key + file.Filename}
                  >
                    <div style={{ marginTop: 5, fontWeight: "bold" }}>
                      {file.Filename}
                    </div>
                    <div style={{ marginTop: 5 }}>{file.Titel}</div>

                  {/* Distinction between file type to show the corresponding icon */}

                    {file.extension === 'zip' 
                       ? (
                      <div className="d-flex justify-content-end">
                         <FontAwesomeIcon icon={faFileZipper} />
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end">
                        <FontAwesomeIcon icon={faFilePdf} />
                      </div>
                    )}


                  </div>
                );
              })}
            </div>
            <div className="col-8">
              {pdfFile && !showSpinner ? (
                <div
                  style={{
                    height: rowHeight - 10,
                    backgroundColor: "white",
                    border: "2px solid black",
                    borderColor:
                      AlphabetColor[Array.from(selectedFile)[0].toLowerCase()],
                    padding: 10,
                    borderRadius: 10,
                    marginTop: 10,
                  }}
                >
                  <div className="d-flex justify-content-center mb-3">
                    <b>{selectedFile}</b>
                  </div>
                  <div className="d-flex justify-content-end">
                    {/* // Show Zoom buttons when the file is PDF */}
                    {isPDF.toString() === "true" && (
                      <button
                        className="btn-round d-flex justify-content-center align-items-center"
                        style={zoomButtonStyle}
                        onClick={() => iframeButtonClick("zoomIn")}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlassPlus} />
                      </button>
                    )}

                    {isPDF.toString() === "true" && (
                      <button
                        className="btn-round d-flex justify-content-center align-items-center"
                        style={{ ...zoomButtonStyle, marginLeft: 10 }}
                        onClick={() => {
                          iframeButtonClick("zoomOut");
                        }}
                      >
                        <FontAwesomeIcon icon={faMagnifyingGlassMinus} />
                      </button>
                    )}

                    <button
                      className="btn-round d-flex justify-content-center align-items-center"
                      style={{ ...zoomButtonStyle, marginLeft: 10 }}
                      onClick={() => {
                        console.log("isPDF? ", isPDF.toString());
                        if (isPDF.toString() === "true") {
                          setIsPDF(true);
                          saveAs(pdfFile, fileSaveName + ".pdf");
                        } else if (isPDF.toString() === "false") {
                          setIsPDF(false);
                          saveAs(pdfFile, fileSaveName + ".zip");
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </button>

                    <button
                      className="btn-round d-flex justify-content-center align-items-center"
                      style={{ ...zoomButtonStyle, marginLeft: 10 }}
                      onClick={() => {
                        setPdfFile(null);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>

                  {isPDF.toString() === "true" ? (
                    <iframe
                      id="pdf-js-viewer"
                      src={"lib/web/viewer.html?file=" + pdfFile}
                      title="webviewer"
                      frameBorder="0"
                      width="100%"
                      height={rowHeight - 100}
                    ></iframe>
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "200px" }}>
                      <FontAwesomeIcon size="10x" icon={faFileZipper} />
                    </div>
                  )}
                </div>
              ) : (
                <>{showSpinner ? <Spinner animation="grow" /> : <></>}</>
              )}
            </div>
          </div>
        )}
      </div>
    </LayoutDesktop>
  );
};

const zoomButtonStyle = {
  maxHeight: 35,
  minHeight: 35,
  maxWidth: 35,
  minWidth: 35,
  fontSize: 15,
  backgroundColor: "black",
};

export default DesktopView;

//download with Filename
