export default {
  a: "#FBDB65",
  b: "#99CC00",
  c: "#EBEBEB",
  d: "#FBDB65",
  e: "#BEDBBF",
  f: "#EBEBEB",
  g: "#99CC00",
  h: "#BEDBBF",
  i: "#EBEBEB",
  j: "#FBDB65",
  k: "#99CC00",
  l: "#EBEBEB",
  m: "#FBDB65",
  n: "#BEDBBF",
  o: "#EBEBEB",
  p: "#99CC00",
  q: "#BEDBBF",
  r: "#EBEBEB",
  s: "#FBDB65",
  t: "#99CC00",
  u: "#EBEBEB",
  v: "#FBDB65",
  w: "#BEDBBF",
  x: "#99CC00",
  y: "#FBDB65",
  z: "#BEDBBF",
};
