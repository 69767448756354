import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import getMobileOperatingSystem from "../../utils/sharedLogics/getMobileOperatingSystem";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";
import useBackButton from "../../utils/hooks/useBackButton";

const Folder = () => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  return (
    <>
      {getMobileOperatingSystem() === "Android" ? (
        <MobileView />
      ) : isPortrait ? (
        <MobileView />
      ) : (
        <DesktopView />
      )}
    </>
  );
};

export default Folder;
