import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isRefreshed: false,
  files: [],
  folders: [],
  sachgruppe: [],
  folderTitle: "",
  sachgruppeTitle: "",
};

const fileMangerSlice = createSlice({
  name: "filemanager",
  initialState,
  reducers: {
    setIsRefreshed(state, action) {
      state.isRefreshed = action.payload;
    },
    setFiles(state, action) {
      state.files = action.payload;
    },
    setSachgruppe(state, action) {
      state.sachgruppe = action.payload;
    },
    setFolders(state, action) {
      state.folders = action.payload;
    },
    setFolderTitle(state, action) {
      state.folderTitle = action.payload;
    },
    setSachgruppeTitle(state, action) {
      state.sachgruppeTitle = action.payload;
    },
  },
});

export const {
  setIsRefreshed,
  setFiles,
  setFolderTitle,
  setFolders,
  setSachgruppe,
  setSachgruppeTitle
} = fileMangerSlice.actions;
export default fileMangerSlice.reducer;
