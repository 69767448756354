import React, { useEffect, useState } from "react";
import DesktopView from "./DesktopView";
import { useDispatch, useSelector } from "react-redux";
import {
  setFolderTitle,
  setSachgruppeTitle,
} from "../FileManager/fileMangerSlice";

const Inhaltstyp = () => {
  const sachgruppe = useSelector((state) => state.fileManager.sachgruppe);
  const dispatch = useDispatch();

  const [loginTime, setLoginTime] = useState(null);


  useEffect(() => {
    const handleLogout = () => {
      localStorage.clear();
      window.location.href = '/login';
    };

    const timeout = setTimeout(handleLogout, 30 * 60 * 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const loggedInTime = localStorage.getItem('loginTime');

    if (loggedInTime) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - parseInt(loggedInTime, 10);

      if (elapsedTime >= 30 * 60 * 1000) {
        handleLogout();
      }
    } else {
      const currentTime = new Date().getTime();
      localStorage.setItem('loginTime', currentTime.toString());
      setLoginTime(currentTime);
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };


  useEffect(() => {
    dispatch(setSachgruppeTitle(""));
  }, []);
  return (
    <>
      <DesktopView folders={sachgruppe} />
    </>
  );
};

export default Inhaltstyp;
