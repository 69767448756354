import { useEffect } from "react";

function useBackButton(callback) {
  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      callback();
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [callback]);
}

export default useBackButton;
