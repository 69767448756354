import {
  faA,
  faB,
  faC,
  faD,
  faE,
  faF,
  faG,
  faH,
  faI,
  faJ,
  faK,
  faL,
  faM,
  faN,
  faO,
  faP,
  faQ,
  faR,
  faS,
  faT,
  faU,
  faV,
  faW,
  faX,
  faY,
  faZ,
} from "@fortawesome/free-solid-svg-icons";

export default {
  a: faA,
  b: faB,
  c: faC,
  d: faD,
  e: faE,
  f: faF,
  g: faG,
  h: faH,
  i: faI,
  j: faJ,
  k: faK,
  l: faL,
  m: faM,
  n: faN,
  o: faO,
  p: faP,
  q: faQ,
  r: faR,
  s: faS,
  t: faT,
  u: faU,
  v: faV,
  w: faW,
  x: faX,
  y: faY,
  z: faZ,
};
